import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./styles/commonStyles.css";

import PartnerLandingPage from "./views/PartnerLandingPage";
import Faq from "./views/Faq";
import TermsAndCondition from "./views/TermsAndCondition";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Contact from "./views/Contact";
import PasswordReset from "./views/PasswordReset";
import ActivateEmail from "./views/ActivateEmail";
import UserLogin from "./views/UserLogin";
import MyAccount from "./views/MyAccount";
import PastOrders from "./views/PastOrders";
import PageNotFound from "./views/PageNotFound";

// load isAuthenticated method
import { isAuthenticated } from "./auth/auth";

// create a private route for customers
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/user-login", state: { from: props.location } }}
        />
      )
    }
  />
);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={PartnerLandingPage} />
        <Route path="/faq" exact component={Faq} />
        <Route path="/terms" exact component={TermsAndCondition} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/reset-password" exact component={PasswordReset} />
        <Route path="/activated" exact component={ActivateEmail} />  
        <Route path="/user-login" exact component={UserLogin} />  
        <PrivateRoute path="/my-account" exact component={MyAccount} />  
        <PrivateRoute path="/my-orders" exact component={PastOrders} />  
        <Route path="/404" exact component={PageNotFound} />
        <Redirect to="404" />
      </Switch>
    </Router>
  );
}

export default App;
