import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import cus from "../../images/cus-support.png";

export default class Footer extends Component {
  state = {
    countryCode: "",
  };

  componentDidMount = () => {
    // get all regions
    axios
      .get(`https://api.spritzer.app/services/regions`)
      .then((regions) => {
        // filter regions which are isAvailable === true
        const filterRegions = regions.data.filter((filter) => {
          return filter.isAvailable === true;
        });

        // get IP info
        axios
          .get(`https://api.spritzer.app/services/ipinfo`, {
            headers: {
              secret: `7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS`,
            },
          })
          .then((ipInfo) => {
            var countryCode = "";

            // filter by country code
            const countryCodeShort = filterRegions.filter(
              (codeShort) =>
                codeShort.country.countryCodeShort === ipInfo.data.countryCode
            );

            // identify country code
            if (countryCodeShort.length > 1) {
              var regionCode = countryCodeShort.filter(
                (code) => code.regionCode === ipInfo.data.regionCode
              );
              countryCode = regionCode[0].country.countryCodeShort;
            } else if (countryCodeShort.length === 1) {
              countryCode = countryCodeShort[0].country.countryCodeShort;
            } else {
              countryCode = "AU";
            }

            this.setState({ countryCode: countryCode });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid bg-white mt-4">
          <div className="container">
            <div className="row m-0">
              <div className="col-xs col-sm-6 col-md-6 col-lg-3 p-0">
                <h5 className="footer-links-headings">Explore</h5>
                <a
                  href="https://dashboard.spritzer.app/manage-orders"
                  target="_blank"
                  className="footer-link"
                  rel="noopener noreferrer"
                >
                  Restaurant Dashboard
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.spritzer.restaurantmanager"
                  target="_blank"
                  className="footer-link"
                  rel="noopener noreferrer"
                >
                  Order Manager App
                </a>
              </div>
              <div className="col-xs col-sm-6 col-md-6 col-lg-3 p-0">
                <h5 className="footer-links-headings">Support</h5>
                <Link
                  to="/contact"
                  className="footer-link"
                  onClick={this.scrollToTop}
                >
                  Contact Us
                </Link>
              </div>
              <div className="col-xs col-sm-6 col-md-6 col-lg-3 p-0">
                <h5 className="footer-links-headings">Legal</h5>

                <Link
                  to="/terms"
                  className="footer-link"
                  onClick={this.scrollToTop}
                >
                  Terms of Services
                </Link>

                <Link
                  to="/privacy-policy"
                  className="footer-link"
                  onClick={this.scrollToTop}
                >
                  Privacy Policy
                </Link>
              </div>

              <div className="col-xs col-sm-6 col-md-6 col-lg-3 p-0">
                <h5 className="footer-links-headings">For Diners</h5>
                <Link to="/my-account" target="_blank" className="footer-link">
                  Past Orders
                </Link>
              </div>
            </div>
            <div className="row m-0">
              <div className="col p-0">
                <hr />
              </div>
            </div>

            <div className="row m-0 d-block d-md-none">
              <div className="col p-0">
                <p
                  style={{
                    color: "#6a6a6a",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  24/7 &nbsp;{" "}
                  <img
                    src={cus}
                    width="22"
                    style={{ marginTop: "-2px" }}
                    alt="support"
                  />{" "}
                  &nbsp; (+94) 712 522 624
                </p>
              </div>
            </div>
            <div className="row m-0 pb-3">
              <div className="col p-0">
                <span style={{ color: "#9b9b9b", fontSize: 15 }}>
                  © {new Date().getFullYear()} Spritzer{" "}
                </span>
              </div>
              <div className="col p-0 d-none d-md-block">
                <p
                  style={{
                    color: "#6a6a6a",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 0,
                  }}
                >
                  24/7 &nbsp;{" "}
                  <img
                    src={cus}
                    width="22"
                    style={{ marginTop: "-2px" }}
                    alt="support"
                  />{" "}
                  &nbsp; (+94) 712 522 624
                </p>
              </div>
              <div className="col p-0">
                <div className="float-right">
                  <a
                    className="social-media-icon"
                    href="https://www.facebook.com/spritzerapp"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="social-media-icon"
                    href="https://www.instagram.com/spritzer.app"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="social-media-icon"
                    href="https://twitter.com/SpritzerApp"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
