import React, { Component } from "react";
import reason1 from "../../images/partner-landing-page/sign-up.png";
import reason2 from "../../images/partner-landing-page/set-up.png";
import reason3 from "../../images/partner-landing-page/increase-sale.png";
import reason4 from "../../images/partner-landing-page/increase-sales.png";

export default class WhyPartnerWithSpritzer extends Component {
  render() {
    return (
      <div className="container">
        <div className="row m-0">
          <div className="col p-0">
            <h2 className="partner-landing-headings">What do you get?</h2>

            <div className="card why-partner-cards">
              <div className="card-body">
                <h5 className="card-title card-heading">Your Own Website</h5>
                <img
                  src={reason1}
                  className="card-img-top why-partner-img"
                  alt="Your own website"
                />
                <p className="card-sub-heading">
                  Mobile responsive & light weight
                </p>
                <p className="card-text">
                  Impress your customers with a fully functional website for
                  mobile and web ordering.
                </p>
              </div>
            </div>

            <div className="card why-partner-cards">
              <div className="card-body">
                <h5 className="card-title card-heading">
                  Order Management Software
                </h5>
                <img
                  src={reason2}
                  className="card-img-top why-partner-img"
                  alt="Order Mgt Software"
                />
                <p className="card-sub-heading">
                  Works in your laptop, phone or tab
                </p>
                <p className="card-text">
                  Manage anything in your restaurant at your own schedule. We
                  provide web based solutions as well as mobile apps.
                </p>
              </div>
            </div>

            <div className="card why-partner-cards">
              <div className="card-body">
                <h5 className="card-title card-heading">
                  Integrated Payment Gateway
                </h5>
                <img
                  src={reason3}
                  className="card-img-top why-partner-img"
                  alt="Integrated Payment Gateway"
                />
                <p className="card-sub-heading">Variety of payment methods</p>
                <p className="card-text">
                  Let your customers pay through their preferred payment method.
                  More options make them loyal customers.
                </p>
              </div>
            </div>
            <div className="card why-partner-cards">
              <div className="card-body">
                <h5 className="card-title card-heading">Advanced Analytics</h5>
                <img
                  src={reason4}
                  className="card-img-top why-partner-img"
                  alt="Advanced Analytics"
                />
                <p className="card-sub-heading">Know your customers better</p>
                <p className="card-text">
                  Increase your sales by providing what your cutomers really
                  want. Experience the power of your own data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
