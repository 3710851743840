import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import ForgetPasswordModal from "../components/ForgetPasswordModal";
import TitleOfPage from "../components/TitleOfPage";

import logo from "../images/spritzer-logo.png";
import bg from "../images/user-login-bg.jpg";

const initialState = {
  email: "",
  password: "",
  loginFail: false,
  emailError: "",
  passwordError: "",

  // redirect
  redirectToMyAccount: false,

  // alert
  emailNotVerified: false,
  showResendSuccess: false,
  showResendFail: false,

  // loading
  loading: false,
};

export default class UserLogin extends Component {
  state = initialState;

  componentDidMount = () => {
    if (document.cookie.includes("auth_token")) {
      this.setState({ redirectToMyAccount: true });
    }
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // validate
  validate = () => {
    let emailError = "";
    let passwordError = "";

    if (this.state.email === "") {
      emailError = "Email is required";
    } else if (!this.state.email.includes("@")) {
      emailError = "Email is invalid";
    }

    if (this.state.password === "") {
      passwordError = "Password is required";
    }

    if (emailError || passwordError) {
      this.setState({ emailError, passwordError });
      return false;
    }

    return true;
  };

  // login when press the enter key
  handleLoginKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("login-btn").click();
    }
  };

  // submit sign-up form
  handleLogin = () => {
    if (this.validate()) {
      // create user data object
      const loginData = {
        email: this.state.email,
        password: this.state.password,
        accountType: "email",
        clientId: "web",
      };

      // set loading
      this.setState({
        loading: true,
        showResendFail: false,
        showResendSuccess: false,
        emailNotVerified: false,
      });

      // send post req to register an user
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/login`, loginData)
        .then((result) => {
          if (result) {
            document.cookie = `auth_token=${JSON.stringify(
              result.data
            )};domain=spritzer.app`;
            // document.cookie = `auth_token=${JSON.stringify(result.data)}`;

            axios
              .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
                headers: {
                  Authorization: `Bearer ${result.data.providerToken}`,
                },
              })
              .then((res) => {
                if (typeof window !== "undefined") {
                  localStorage.setItem("profile", JSON.stringify(res.data));
                }
                this.setState({
                  redirectToMyAccount: true,
                  loading: false,
                });
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => {
          if (err.response.data.code === 1010) {
            this.setState(initialState);
            this.setState({ loginFail: true });
          } else if (err.response.data.code === 1011) {
            this.setState({
              emailError: "",
              passwordError: "",
              loading: false,
              emailNotVerified: true,
              loginFail: false,
            });
          } else {
            this.setState({
              emailError: "",
              passwordError: "",
              loading: false,
              loginFail: true,
            });
          }
        });
    }
  };

  resendEmailVerification = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/activate/resend?email=${this.state.email}`
      )
      .then((res) => {
        this.setState({
          emailNotVerified: false,
          showResendSuccess: true,
          loginFail: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          emailNotVerified: false,
          showResendFail: true,
          loginFail: false,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="User Login | Spritzer" />

        {/* direct to  */}
        {this.state.redirectToMyAccount === true ? (
          <Redirect to="/my-account" />
        ) : null}

        <div className="row m-0 ">
          <div
            className="p-0 m-0 login-first-col"
            style={{
              background: `url(https://res.cloudinary.com/spritzer/image/upload/v1587145542/static/menu/food-bg5.jpg)`,
            }}
          >
            <div className="login-div">
              <a
                href={
                  process.env.REACT_APP_API_URL ===
                  "https://api-dev.spritzer.app"
                    ? "https://www-dev.spritzer.app/"
                    : "https://www.spritzer.app/"
                }
              >
                <img
                  src={logo}
                  className="company-logo"
                  alt="Spritzer Business Logo"
                />
              </a>
              <h1 className="login-page-heading">Welcome to User Login</h1>
              {this.state.loginFail ? (
                <p
                  style={{
                    fontSize: "14px",
                    color: "#f00",
                    marginBottom: "5px",
                  }}
                >
                  Email and/or password is incorrect.
                </p>
              ) : null}

              {this.state.emailNotVerified ? (
                <React.Fragment>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#f00",
                      marginBottom: "5px",
                    }}
                  >
                    Your email has not been verified. Please check your inbox
                    for the verification email or click "Resend" to send it
                    again.
                  </p>
                  <button
                    type="button"
                    onClick={this.resendEmailVerification}
                    style={{
                      background: "#2196F3",
                      color: "#fff",
                      border: 0,
                      borderRadius: 3,
                      margin: "auto",
                      padding: "3px 10px",
                      display: "block",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    Resend
                  </button>
                </React.Fragment>
              ) : null}

              {this.state.showResendSuccess ? (
                <p
                  className="text-success text-center"
                  style={{
                    fontSize: 14,
                  }}
                >
                  <i className="fas fa-check"> </i> Verification Mail Sent.
                </p>
              ) : null}

              {this.state.showResendFail ? (
                <p
                  className="text-danger text-center"
                  style={{
                    fontSize: 14,
                  }}
                >
                  <i className="fas fa-times"> </i> Opps! Something Went Wrong.
                </p>
              ) : null}
              <form>
                <div className="form-row m-0">
                  <div className="form-group w-100 mb-2 p-0">
                    <label htmlFor="email" className="login-form-label ml-0">
                      Email
                    </label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": this.state.emailError,
                      })}
                      name="email"
                      id="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.emailError}
                    </div>
                  </div>
                </div>
                <div className="form-row m-0">
                  <div className="form-group w-100 mb-2 mt-1 p-0">
                    <label htmlFor="pwd" className="login-form-label ml-0">
                      Password
                    </label>
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": this.state.passwordError,
                      })}
                      name="password"
                      id="pwd"
                      value={this.state.password}
                      onChange={this.handleChange}
                      onKeyUp={this.handleLoginKeyUp}
                    />
                    <div className="invalid-feedback">
                      {this.state.passwordError}
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="login-btn"
                  onClick={this.handleLogin}
                  id="login-btn"
                >
                  {this.state.loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}
                  {this.state.loading ? "Logging..." : "Log in"}
                </button>
                <p
                  className="text-center orange-text mt-3 mb-0"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  data-toggle="modal"
                  data-target="#forgetpasswordModel"
                >
                  Forgot your password?
                </p>
                {/* <p className="text-center" style={{ fontSize: "14px" }}>
                  Have no account yet?{" "}
                  <Link to="/sign-up/user-details" className="orange-text">
                    Sign Up
                  </Link>
                </p> */}
              </form>
            </div>
          </div>
        </div>

        <ForgetPasswordModal />
      </React.Fragment>
    );
  }
}
