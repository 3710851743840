import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

export default class Order extends Component {
  state = {
    image: "",
    restName: "",
  };
  componentDidMount = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/listings/${this.props.listingId}`
      )
      .then((res) => {
        this.setState({
          extId: res.data.extId,
          image: res.data.images[0].url,
          restName: res.data.name,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    // console.log(this.props.orders);
    return (
      <React.Fragment>
        <div className="row m-0 border-bottom past-orders-div">
          <div className="col-sm-4 rest-col">
            <div className="img-div">
              <img src={this.state.image} width="100%" alt={this.state.image} />
              <Link to={`https://${this.state.extId}.spritzer.app`} target="_blank" className="open">Open <i className="fas fa-external-link-alt pl-1"></i></Link>
            </div>
          </div>
          <div className="col-sm-8 receipt-col">
            <h5 style={{ fontSize: 20 }}>{this.state.restName}</h5>
            <p
              style={{
                color: "rgb(84, 84, 84)",
                fontSize: 14,
                marginBottom: 0,
              }}
            >
              {this.props.orders.orderItems.reduce(
                (currentValue, nextValue) => {
                  return currentValue + nextValue.quantity;
                },
                0
              )}{" "}
              items for Rs. {this.props.orders.total.toFixed(2)} &nbsp; &nbsp;{" "}
              <i className="fas fa-circle align-middle" style={{ fontSize: 3 }}>
                {" "}
              </i>{" "}
              &nbsp;
              {moment
                .utc(this.props.orders.createTime)
                .local()
                .format("MMM DD")}{" "}
              at{" "}
              {moment
                .utc(this.props.orders.createTime)
                .local()
                .format("hh:mm A")}
            </p>
            <p
              style={{ color: "#ff7068", fontSize: 12, fontWeight: 500 }}
              type="button"
              data-toggle="modal"
              data-target={`#receipt-modal${this.props.orders.orderId}`}
            >
              View receipt
            </p>
          </div>
        </div>

        <div
          className="modal fade receipt-modal"
          id={`receipt-modal${this.props.orders.orderId}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close map-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="modal-body p-0">
                <div className="row m-0">
                  <div className="col p-0">
                    <div className="header">
                      <p className="receipt-date">
                        {" "}
                        {moment
                          .utc(this.props.orders.createTime)
                          .local()
                          .format("ddd MMM DD, YYYY")}
                      </p>
                      <h3 className="rest-name">{this.state.restName}</h3>
                    </div>
                    <div
                      className="row m-3 my-4 border-bottom"
                      style={{ fontSize: 26, fontWeight: 500 }}
                    >
                      <div className="col p-0">
                        <p>Total</p>
                      </div>
                      <div className="col p-0 text-right">
                        Rs. {this.props.orders.total.toFixed(2)}
                      </div>
                    </div>
                    {this.props.orders.orderItems.map((item, i) => (
                      <div
                        className="row mx-3"
                        key={i}
                        style={{ fontSize: 14, color: "#6f6f6f" }}
                      >
                        <div className="col p-0">
                          <p>
                            {item.quantity}x {item.item.name}
                          </p>
                        </div>
                        <div className="col p-0 text-right">
                          Rs. {item.subtotal.toFixed(2)}
                        </div>
                      </div>
                    ))}
                    <div
                      className="row mx-3 border-top"
                      style={{ fontWeight: 500, marginTop: 9 }}
                    >
                      <div className="col p-0 pt-3">Subtotal</div>
                      <div className="col p-0 pt-3 text-right">
                        Rs. {this.props.orders.subtotal.toFixed(2)}
                      </div>
                    </div>
                    <div className="row mx-3 mt-1">
                      <div className="col p-0 pb-3">Delivery Fee</div>
                      <div className="col p-0 pb-3 text-right">
                        Rs. {this.props.orders.deliveryFee.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
