import React, { Component } from "react";
import { Link } from "react-router-dom";

import reminderBanner from "../../images/reminder-banner.png";

export default class SignUpReminder extends Component {
  render() {
    return (
      <div
        className="container-fluid SignUpReminder-div"
        style={{
          background: `url(${reminderBanner})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container reminder-container">
          <div className="row m-0">
            <div className="col p-0">
              <div className="row mx-0">
                <div className="col-sm-8 p-0 px-md-2">
                  <h1 className="reminder-heading d-block">
                    How can you manage your digital presence?
                  </h1>
                  <p className="text-white mb-0 mt-1">
                    Book a session with one of our digital experts.
                  </p>
                </div>
                <div className="col-sm-4 p-0 px-md-2">
                  <Link
                    to="/contact"
                    className="SignUpReminder-sign-up-btn jumbotron-sign-up-btn"
                  >
                    <p>Free Consultancy Session</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
