import React, { Component } from "react";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";

import TitleOfPage from "../components/TitleOfPage";
import Navbar from "../components/partnerLandingPageComponents/Navbar";
// import Footer from "../components/menu/Footer";

import "../styles/myAccount.css";

import { isAuthenticated } from "../auth/auth";

export default class MyAccount extends Component {
  state = {
    listingId: "",
    restDetails: null,
    restDeliveryDetails: null,

    pay: [],
    addresses: [],
    email: "",
    username: "",
    oldPassword: "",
    newPassword: "",
    cardId: "",

    oldPasswordError: "",
    newPasswordError: "",

    showEdit: false,
    showDelCard: false,
    showDelAddress: false,

    // show alert
    pwdSuccess: false,
    loading: false,
    nameLoading: false,
  };

  componentDidMount = () => {
    // get listing id
    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, {
        headers: {
          Authorization: `Bearer ${
            isAuthenticated().providerToken
          }`,
        },
      })
      .then((res) => {
        this.setState({ paymentSources: res.data });
      })
      .catch((err) => console.log(err));

    axios
      .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${
            isAuthenticated().providerToken
          }`,
        },
      })
      .then((res) => {
        localStorage.setItem("profile", JSON.stringify(res.data))

        this.setState({
          email: res.data.email,
          username: res.data.name,
          addresses: res.data.deliveryAddresses,
        });
      })
      .catch((err) => console.log(err));
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  saveName = (e) => {
    this.setState({ nameLoading: true });

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user/profile`,
        { name: this.state.username },
        {
          headers: {
            Authorization: `Bearer ${
              isAuthenticated().providerToken
            }`,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("profile", JSON.stringify(res.data))

        this.setState({ showEdit: false, nameLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ nameLoading: false });
      });
  };

  // validate
  validate = () => {
    let oldPasswordError = "";
    let newPasswordError = "";

    if (this.state.oldPassword === "") {
      oldPasswordError = "Old password are required";
    }

    if (this.state.newPassword === "") {
      newPasswordError = "New password is required";
    }

    if (oldPasswordError || newPasswordError) {
      this.setState({
        oldPasswordError,
        newPasswordError,
      });
      return false;
    }

    return true;
  };

  changePassword = (e) => {
    e.preventDefault();

    if (this.validate()) {
      const data = {
        email: this.state.email,
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        clientId: "web",
      };

      // set loading
      this.setState({ loading: true });

      axios
        .post(`${process.env.REACT_APP_API_URL}/user/password/change`, data, {
          headers: {
            Authorization: `Bearer ${
              isAuthenticated().providerToken
            }`,
          },
        })
        .then((res) => {
          document.cookie = `auth_token=${JSON.stringify(res.data)};domain=spritzer.app`;

          this.setState({
            pwdSuccess: true,
            loading: false,
            oldPassword: "",
            newPassword: "",
            oldPasswordError: "",
            newPasswordError: "",
          });
        })
        .catch((err) => {
          if (err.response.data.code === 1010) {
            this.setState({
              oldPasswordError: "Current password is incorrect.",
              loading: false,
            });
          } else if (err.response.data.code === 1006) {
            this.setState({
              newPasswordError: err.response.data.message,
              loading: false,
            });
          } else {
            this.setState({
              newPasswordError: "Password hasn't been changed successfully!",
              loading: false,
            });
          }
        });
    }
  };

  updateDefault = (cardId) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v2/paymentsources/${cardId}/updatedefault`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              isAuthenticated().providerToken
            }`,
          },
        }
      )
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v2/paymentsources`, {
            headers: {
              Authorization: `Bearer ${
                isAuthenticated().providerToken
              }`,
            },
          })
          .then((res) => {
            console.log(res.data);

            this.setState({ paymentSources: res.data });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  deletePayCard = (cardId) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v2/paymentsources/${cardId}`, {
        headers: {
          Authorization: `Bearer ${
            isAuthenticated().providerToken
          }`,
        },
      })
      .then((res) => {
        console.log(res.data);

        const filter = this.state.paymentSources.filter(
          (filter) => filter.id !== cardId
        );

        this.setState({ paymentSources: filter, showDelCard: false });
      })
      .catch((err) => console.log(err));
  };

  deleteAddress = (addressId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/my_profile/delivery_addresses/${addressId}`,
        {
          headers: {
            Authorization: `Bearer ${
              isAuthenticated().providerToken
            }`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        const filter = this.state.addresses.filter(
          (filter) => filter.id !== addressId
        );

        this.setState({ addresses: filter, showDelAddress: false });
      })
      .catch((err) => console.log(err));
  };

  onCancel = () => {
    this.setState({
      pwdSuccess: false,
      showDelCard: false,
      showDelAddress: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <TitleOfPage title="My Account | Spritzer" />

        <div className="container my-account-container"  style={{minHeight: 825}}>
          <div className="row m-0">
            <div className="col p-0">
              <h2 className="mt-4">My Account</h2>
              <h5 className="mt-5 mb-4">Basic Details</h5>
              <form>
                <div className="form-group row">
                  <label
                    htmlFor="staticEmail"
                    className="col-sm-2 col-form-label"
                  >
                    Email
                  </label>
                  <div className="col-10 col-sm-7 col-md-5 col-lg-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="staticEmail"
                      value={this.state.email}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="username" className="col-sm-2 col-form-label">
                    Name
                  </label>
                  <div className="col-10 col-sm-7 col-md-5 col-lg-4">
                    {!this.state.showEdit ? (
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.username}
                        disabled
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        autoComplete="off"
                        value={this.state.username}
                        onChange={this.handleInput}
                      />
                    )}
                  </div>
                  <div className="col-2 col-sm-1 col-md-1 col-lg-1 pt-2 pl-0">
                    {!this.state.showEdit ? (
                      <span
                        className="edit-save-btn"
                        onClick={() => this.setState({ showEdit: true })}
                      >
                        Edit
                      </span>
                    ) : (
                      <span className="edit-save-btn" onClick={this.saveName}>
                        {this.state.nameLoading ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                            style={{ color: "#ff7068" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : null}
                        {this.state.nameLoading ? "" : "Save"}
                      </span>
                    )}
                  </div>
                </div>
              </form>
              <h5 className="mt-5 mb-4">Change Password</h5>
              <form>
                <div className="form-group row">
                  <label
                    htmlFor="oldPassword"
                    className="col-sm-2 col-form-label"
                  >
                    Current
                  </label>
                  <div className="col-9 col-sm-7 col-md-5 col-lg-3">
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": this.state.oldPasswordError,
                      })}
                      id="oldPassword"
                      name="oldPassword"
                      value={this.state.oldPassword}
                      onChange={this.handleInput}
                    />
                    <div className="invalid-feedback">
                      {this.state.oldPasswordError}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="newPassword"
                    className="col-sm-2 col-form-label"
                  >
                    New
                  </label>
                  <div className="col-9 col-sm-7 col-md-5 col-lg-3">
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": this.state.newPasswordError,
                      })}
                      id="newPassword"
                      name="newPassword"
                      value={this.state.newPassword}
                      onChange={this.handleInput}
                    />
                    <div className="invalid-feedback">
                      {this.state.newPasswordError}
                    </div>
                  </div>
                  <div className="col-3 col-sm-1 col-md-1 col-lg-1 pt-2 pl-0">
                    {/* <button style={ChangeBtn} onClick={this.changePassword}>
                      Change
                    </button> */}
                    <span
                      className="edit-save-btn"
                      onClick={this.changePassword}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ color: "#ff7068" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}
                      {this.state.loading ? "" : "Change"}
                    </span>
                  </div>
                </div>
              </form>
              <h5 className="mt-5 mb-3">Payment Methods</h5>
              <label
                htmlFor="inputPassword"
                className="col-form-label mb-3"
                style={{ fontSize: 14 }}
              >
                Card Payments
              </label>
              {this.state.paymentSources &&
                this.state.paymentSources.map((pay, i) => (
                  <div
                    className="row m-0 mb-3 edit-card-payment-div"
                    key={i}
                    style={{ width: 300 }}
                  >
                    <div className="col-8 p-0">
                      {pay.isDefault ? (
                        <i className="fas fa-check mx-4 text-success"></i>
                      ) : (
                        <i
                          className="fas fa-check mx-4 others"
                          onClick={() => this.updateDefault(pay.id)}
                        ></i>
                      )}
                      {pay.isDefault ? (
                        <React.Fragment>
                          <i
                            className="fas fa-credit-card"
                            style={{ fontSize: 22 }}
                          ></i>
                          <i className="fas fa-circle"></i>
                          <i className="fas fa-circle"></i>
                          <i className="fas fa-circle"></i>
                          <i className="fas fa-circle"></i>
                          <span>{pay.maskedCardNumber}</span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <i
                            className="fas fa-credit-card"
                            style={{ fontSize: 22, color: "#828282" }}
                          ></i>
                          <i
                            className="fas fa-circle"
                            style={{ color: "#828282" }}
                          ></i>
                          <i
                            className="fas fa-circle"
                            style={{ color: "#828282" }}
                          ></i>
                          <i
                            className="fas fa-circle"
                            style={{ color: "#828282" }}
                          ></i>
                          <i
                            className="fas fa-circle"
                            style={{ color: "#828282" }}
                          ></i>
                          <span style={{ color: "#828282" }}>
                            {pay.maskedCardNumber}
                          </span>
                          <i
                            className="fas fa-trash-alt ml-4"
                            onClick={() =>
                              this.setState({
                                showDelCard: true,
                                cardId: pay.id,
                              })
                            }
                          ></i>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                ))}

              <h5 className="mt-5 mb-3">Delivery Addresses</h5>
              <table className="address-table" style={{ fontSize: 14 }}>
                <tbody>
                  {this.state.addresses.map((address, i) => (
                    <tr key={i}>
                      <td className="align-top">{i + 1}</td>
                      <td>
                        <p className="mb-0">{address.line1}</p>
                        <p className="mb-0">{address.line2}</p>
                        <p className="mb-0">{address.city}</p>
                      </td>
                      <td className="align-top">
                        <i
                          className="fas fa-trash-alt"
                          onClick={() =>
                            this.setState({
                              showDelAddress: true,
                              addressId: address.id,
                            })
                          }
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <Footer /> */}

        {this.state.pwdSuccess ? (
          <SweetAlert
            success
            title="Successfull"
            onConfirm={this.onCancel}
            confirmBtnText="Ok"
            confirmBtnStyle={{
              background: "#f26666",
              border: 0,
              width: 70,
            }} // background: "#f26666", border: 0, width: 70,
          >
            Password has been changed successfully!
          </SweetAlert>
        ) : null}

        {this.state.showDelCard ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, Delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => this.deletePayCard(this.state.cardId)}
            onCancel={this.onCancel}
            focusCancelBtn
            btnSize="sm"
            cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
          >
            You will not be able to recover this card!
          </SweetAlert>
        ) : null}

        {this.state.showDelAddress ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, Delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => this.deleteAddress(this.state.addressId)}
            onCancel={this.onCancel}
            focusCancelBtn
            btnSize="sm"
            cancelBtnStyle={{ color: "#504f4f", textDecoration: "none" }}
          >
            You will not be able to recover this address!
          </SweetAlert>
        ) : null}
      </React.Fragment>
    );
  }
}

