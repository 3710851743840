import React, { Component } from "react";
import step1 from "../../images/partner-landing-page/create-customize.png";
import step2 from "../../images/partner-landing-page/add-to-photo.png";
import step3 from "../../images/partner-landing-page/accept-orders-online.png";

export default class HowSpritzerWorks extends Component {
  render() {
    return (
      <div className="container">
        <div className="row m-0">
          <div className="col p-0">
            <h2 className="partner-landing-headings pb-0">
            Build Your Online Restaurant
            </h2>
            <p className="how-sub-heading text-center pb-4">
            in 3 easy steps
            </p>
            <div
              className="row m-0 bg-white"
              style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="card how-spritzer-works-cards">
                <div className="card-body">
                  <h5 className="card-title card-heading-how">Create & Customize</h5>
                  <img
                    src={step1}
                    className="card-img-top how-spritzer-works-img"
                    alt="Create & Customize"
                  />
                  <p className="how-works-sub-heading">
                  Choose your own URL, add basic info and register your restaurant.
                  </p>
                </div>
              </div>

              <div className="card how-spritzer-works-cards">
                <div className="card-body card-body-mobile">
                  <h5 className="card-title card-heading-how">Add Photo Menu</h5>
                  <img
                    src={step2}
                    className="card-img-top how-spritzer-works-img"
                    alt="Add Photo Menu"
                  />
                  <p className="how-works-sub-heading">
                  Add photos and create an attractive menu for all your customers.
                  </p>
                </div>
              </div>

              <div className="card how-spritzer-works-cards">
                <div className="card-body card-body-mobile">
                  <h5 className="card-title card-heading-how">
                  Get Orders Online
                  </h5>
                  <img
                    src={step3}
                    className="card-img-top how-spritzer-works-img"
                    alt="Get Orders Online"
                  />
                  <p className="how-works-sub-heading">
                  Online restaurant that works 24x7 with every feature it needs to increase sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
