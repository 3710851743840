exports.isAuthenticated = () => {
    if (typeof window !== "undefined") {
      if (document.cookie.includes("auth_token")) {
        return JSON.parse(document.cookie.match(new RegExp('(^| )auth_token=([^;]+)'))[2]);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };