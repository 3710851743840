import React from "react";

export default function FaqLK() {
  return (
    <div className="row">
      <div className="col faq-for-businesses">
        <div id="accordion" className="accordion mb-4">
          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse1"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">What is Spritzer?</span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse1">
              <div className="card-body px-0 pt-0 pb-3">
                <p>
                  Spritzer is a mobile app that allows its users to explore and
                  reserve exclusive happy hour deals from participating
                  merchants throughout the day. Spritzer lets its users try out
                  new places and enjoy drinks and food at discounted rates.
                </p>
                <p className="mb-0">
                  Our merchant partners can post happy hour offers to the system
                  and attract Spritzer users whenever they feel like serving
                  more customers. You can schedule happy hours for a future date
                  or make them go live immediately. You get the opportunity to
                  reach a whole new audience each day.
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse2"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                Do merchant partners have a mobile App?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse2">
              <div className="card-body px-0 pt-0 pb-3">
                <p>
                  Yes, you can find the Spritzer-Business app in the Android
                  PlayStore and Apple AppStore. Use the links below to download
                  it. (Go to{" "}
                  <a
                    href="https://business.spritzer.app/"
                    className="faq-terms-privacy-links"
                  >
                    business.spritzer.app
                  </a>{" "}
                  to access your account from a computer)
                </p>
                <p className="mb-0">
                  <a
                    href="https://itunes.apple.com/au/app/spritzer-business/id1444816793?mt=8"
                    className="faq-terms-privacy-links"
                  >
                    Apple AppStore
                  </a>
                </p>
                <p className="mb-0">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.spritzer.business"
                    className="faq-terms-privacy-links"
                  >
                    Google PlayStore
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse3"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                Is Spritzer available in my area?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse3">
              <div className="card-body px-0 pt-0 pb-3">
                <p className="mb-0">
                  We have just launched in Colombo. Currently, we’re getting our
                  business partners from Colombo on board and we will expand to
                  outer suburbs soon.
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse4"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                How does Spritzer work?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse4">
              <div className="card-body px-0 pt-0 pb-3">
                <p>
                  We believe happy hours should not be limited from 5 to 7. Our
                  platform has been designed in such a way that Spritzer
                  merchant partners can post happy hours whenever they feel like
                  attracting more customers. Happy Hours can be set to go live
                  immediately as well as at a future time. Control is yours!
                </p>
                <p className="mb-0">
                  In addition to that, you get the ability to understand your
                  customers and the market with Spritzer Analytics at no cost.
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse5"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                What Kind of happy hours can I post?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse5">
              <div className="card-body px-0 pt-0 pb-3">
                <p>
                  We have categorized our Happy Hours into four categories to
                  cater to our users the way they prefer. Merchant partners can
                  choose one and post specials.
                </p>
                <ul className="ml-5 mb-0">
                  <li>2-4-1 (Buy 1 Get 1 Free)</li>
                  <li>Half Price</li>
                  <li>% Off of the total bill</li>
                  <li>Custom reduced pricing for items</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse6"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                How can I list my business on the Spritzer platform?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse6">
              <div className="card-body px-0 pt-0 pb-3">
                <p className="mb-0">
                  You can create an account in Spritzer App within 2 minutes of
                  downloading it. Claim your business if we have already added
                  it to the platform or you can create your business in the
                  system in less than 5 minutes. No setup fees or monthly
                  charges so you can try and opt-out if you don’t like the App,
                  without any obligation.
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse7"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                Do I have to pay to use the Spritzer App?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse7">
              <div className="card-body px-0 pt-0 pb-3">
                <p className="mb-0">
                  Yes, we charge a fee for each customer we bring to your door.
                  Our customers have a minimum spend commitment when they
                  reserve a voucher and we charge only a commission from that
                  minimum spend no matter how large their bill size is.
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse8"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                How do I know if the voucher provided by the user is valid?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse8">
              <div className="card-body px-0 pt-0 pb-3">
                <p className="mb-0">
                  Merchant partners can use the Spritzer-Business app to scan
                  the QR code in the customer’s claim voucher so the App shows
                  you the eligibility in real-time. Alternatively, we place a QR
                  code at your cashier for customers to validate their vouchers
                  in front of you.
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse9"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                Do customers have a minimum spend limit to be eligible?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse9">
              <div className="card-body px-0 pt-0 pb-3">
                <p className="mb-0">
                  Yes, there’s a minimum spend defined for each venue. This
                  minimum spend is decided at the time of the merchant sign up
                  verification with the agreement of both parties (Spritzer and
                  the merchant).
                </p>
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <div
              className="card-header collapsed px-0"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapse10"
              aria-expanded="true"
              style={{ border: 0 }}
            >
              <span className="card-title orange-text">
                Can I opt-out anytime?
              </span>
              <i className="fas fa-angle-right"></i>
            </div>

            <div className="card-block collapse" id="collapse10">
              <div className="card-body px-0 pt-0 pb-3">
                <p className="mb-0">
                  Yes, you can. We respect your right to operate your business
                  the way you want. You can use (or leave) our platform anytime
                  without any contractual or monetary obligations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
