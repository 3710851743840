import React, { Component } from "react";
import { Lines } from "react-preloaders";

import Navbar from "../components/Navbar";
import Jumbotron from "../components/partnerLandingPageComponents/Jumbotron";
import WhyPartnerWithSpritzer from "../components/partnerLandingPageComponents/WhyPartnerWithSpritzer";
// import Testimonial from "../components/partnerLandingPageComponents/Testimonial";
// import SpritzerStats from "../components/partnerLandingPageComponents/SpritzerStats";
import HowSpritzerWorks from "../components/partnerLandingPageComponents/HowSpritzerWorks";
import Features from "../components/partnerLandingPageComponents/Features";
import OurClients from "../components/partnerLandingPageComponents/OurClients";
import Pricing from "../components/partnerLandingPageComponents/Pricing";
// import PartnersTicker from "../components/partnerLandingPageComponents/PartnersTicker";
import SignUpReminder from "../components/partnerLandingPageComponents/SignUpReminder";
// import Blog from "../components/partnerLandingPageComponents/Blog";
import Footer from "../components/partnerLandingPageComponents/Footer";
import TitleOfPage from "../components/TitleOfPage";

export default class PartnerLandingPage extends Component {
  state = {
    // pre loading
    loading: false,
  };

  componentDidMount = () => {
    // clear alert
    // setTimeout(() => this.setState({ loading: false }), 1000);
  };

  render() {
    return (
      <div className="container-fluid p-0" style={{ background: "#FBFBFB" }}>
        <TitleOfPage title="Spritzer | Build Your Online Restaurant" />

        <Lines
          color="#fff"
          background="#f26666"
          animation="slide-down"
          customLoading={this.state.loading}
        />

        <Navbar />
        <Jumbotron />
        {/* <Testimonial />
         */}
        <WhyPartnerWithSpritzer />
        {/* <SpritzerStats /> */}
        <Features />
        {/* <OurClients /> */}
        <HowSpritzerWorks />
        {/* <Pricing /> */}
        {/* <PartnersTicker /> */}
        <SignUpReminder />
        {/* <Blog /> */}
        <div id="fb-root"></div>
        <div
          class="fb-customerchat"
          attribution="install_email"
          page_id="102868494553762"
          theme_color="#ff6961"
          logged_in_greeting="Hi, How can we help you?"
          logged_out_greeting="Hi, How can we help you?"
        ></div>
        <Footer />
      </div>
    );
  }
}
