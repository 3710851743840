import React, { Component } from "react";
import price from "../../images/pricing.png"

export default class Pricing extends Component {
  render() {
    return (
      <div className="container">
        <div className="row m-0">
          <div className="col p-0">
            <h2 className="partner-landing-headings pb-0" style={{marginBottom: 30}}>Pricing</h2>
            <div
              className="row m-0 pricing-sec"
            >
              <div className="col" style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)" }}> 
                <p className="pricing-info">
                  Simple transparent pricing for a set of continuously improving
                  features. We offer customized pricing for home based small
                  businesses. Come talk to us.{" "}
                </p>
                {/* <p className="price text-center"><strike>Rs. 9,990.00</strike> and <span>Rs. 6,990.00 *</span></p> */}
                <img src={price} className="price"  />

                <table>
                  <tbody>
                    <tr className="pricing-features">
                      <td><i className="fas fa-star"></i></td>
                      <td>No set up fees</td>
                    </tr>
                    <tr className="pricing-features">
                      <td><i className="fas fa-percent"></i></td>
                      <td>No commissions on sales</td>
                    </tr>
                    <tr className="pricing-features">
                      <td><i className="fas fa-credit-card"></i></td>
                      <td>2.99% credit card processing fee</td>
                    </tr>
                  </tbody>
                </table>


                <p className="discount"><b style={{color: "#ff6961"}}>*</b> 30% discount up to 12 months if you register now during the COVID19 crisis.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
