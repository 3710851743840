import React, { Component } from "react";
import axios from "axios";

import Navbar from "../components/Navbar";
import Footer from "../components/partnerLandingPageComponents/Footer";
import FaqAU from "../components/faq/FaqAU";
import FaqLK from "../components/faq/FaqLK";
import TitleOfPage from "../components/TitleOfPage";

import "../styles/faqStyles.css";

export default class Faq extends Component {
  state = {
    countryCode: ""
  };

  componentDidMount = () => {
    // get all regions
    axios
      .get(`https://api.spritzer.app/services/regions`)
      .then(regions => {
        // filter regions which are isAvailable === true
        const filterRegions = regions.data.filter(filter => {
          return filter.isAvailable === true;
        });

        // get IP info
        axios
          .get(`https://api.spritzer.app/services/ipinfo`, {
            headers: {
              secret: `7EBG2G4JkcaFWVEJaKFTyjzAdMww9QCK7J9WyMdGRcyTQggGG42kqYvtMWuATxWqVSxWpLhGpVHc4XEy2nQjqUwaa7FFm7vGwHHL5eSNGqAZZ3RSyVCzSHTR4yt3u8nS`
            }
          })
          .then(ipInfo => {
            var countryCode = "";

            // filter by country code
            const countryCodeShort = filterRegions.filter(
              codeShort =>
                codeShort.country.countryCodeShort === ipInfo.data.countryCode
            );

            // identify country code
            if (countryCodeShort.length > 1) {
              var regionCode = countryCodeShort.filter(
                code => code.regionCode === ipInfo.data.regionCode
              );
              countryCode = regionCode[0].country.countryCodeShort;
            } else if (countryCodeShort.length === 1) {
              countryCode = countryCodeShort[0].country.countryCodeShort;
            } else {
              countryCode = "AU";
            }

            this.setState({ countryCode });
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="FAQ | Spritzer" />

        <div className="container-fluid p-0" style={{ background: "#FBFBFB" }}>
          <Navbar />
          <div className="container">
            <div className="row m-0">
              <div className="col p-0">
                <h2 className="partner-landing-headings">
                  FAQ - Spritzer Business
                </h2>
              </div>
            </div>
            {this.state.countryCode === "AU" ? <FaqAU /> : <FaqLK />}
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
