import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

export default class Navbar extends Component {
  state = {
    extId:
      localStorage.getItem("restaurant") &&
      JSON.parse(localStorage.getItem("restaurant")).extId,
    name: "",

    redirectToMenu: false,
  };

  componentDidMount = () => {
    if (document.cookie.includes("auth_token")) {

      axios
        .get(`${process.env.REACT_APP_API_URL}/user/profile`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(document.cookie.match(new RegExp('(^| )auth_token=([^;]+)'))[2]).providerToken
            }`,
          },
        })
        .then((res) => {
          if (typeof window !== "undefined") {
            localStorage.setItem("profile", JSON.stringify(res.data));
          }

          this.setState({
            name: res.data.name,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  handleLogout = () => {
    Cookies.remove("auth_token", { path: "", domain: ".spritzer.app" });
    localStorage.removeItem("profile");
    localStorage.removeItem("listing");

    this.setState({ redirectToMenu: true });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.redirectToMenu ? (
          <Redirect to="/user-login" />
        ) : null}
        <nav
          className="navbar sticky-top navbar-light p-3"
          style={{ background: "#151b26" }}
        >
          <div className="ml-auto">
            <div className="d-inline ml-4">
              <div
                className="btn-group float-right"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="dropdown-toggle text-light"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="user-name mr-3">{this.state.name}</span>
                  <i class="fas fa-user"></i>
                </span>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  style={{ zIndex: 9999, fontSize: 13 }}
                >
                  <Link
                    className="dropdown-item text-dark"
                    to="/my-account"
                    style={{ lineHeight: "30px" }}
                  >
                    <i className="fas fa-user"></i> &nbsp; My Account
                  </Link>
                  <Link
                    className="dropdown-item text-dark"
                    to="/my-orders"
                    style={{ lineHeight: "30px" }}
                  >
                    <i className="fas fa-receipt"></i> &nbsp; Orders
                  </Link>
                  <Link
                    className="dropdown-item text-dark"
                    to="#"
                    onClick={this.handleLogout}
                    style={{ lineHeight: "30px" }}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
