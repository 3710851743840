import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import queryString from "query-string";
import Navbar from "../components/Navbar";
import Footer from "../components/partnerLandingPageComponents/Footer";
import TitleOfPage from "../components/TitleOfPage";

import "../styles/passwordReset.css";

export default class PasswordReset extends Component {
  state = {
    password: "",
    confirmPassword: "",

    passwordError: "",
    confirmPasswordError: "",

    // alert
    resetSuccess: false,
    resetFail: "",
  };

  // handle inputs
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // validate
  validate = () => {
    let passwordError = "";
    let confirmPasswordError = "";

    if (this.state.password === "") {
      passwordError = "Password is required";
    } else if (this.state.password.length < 6) {
      passwordError = "Password should be between 6 and 60 characters";
    }

    if (this.state.confirmPassword === "") {
      confirmPasswordError = "Confirm password is required";
    } else if (this.state.password !== this.state.confirmPassword) {
      confirmPasswordError = "Passwords should be match";
    }

    if (passwordError || confirmPasswordError) {
      this.setState({ passwordError, confirmPasswordError });
      return false;
    }

    return true;
  };

  // login when press the enter key
  handleSubmitKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Trigger the button element with a click
      document.getElementById("submit").click();
    }
  };

  // submit sign-up form
  handleSubmit = () => {
    if (this.validate()) {
      // create user data object
      const data = {
        token: queryString.parse(this.props.location.search).token,
        newPassword: this.state.password,
        clientId: "web",
      };

      // set loading
      this.setState({ loading: true });

      axios
        .post(`${process.env.REACT_APP_API_URL}/user/password/change`, data)
        .then((res) => {
          this.setState({
            resetSuccess: true,
            loading: false,
            password: "",
            confirmPassword: "",
            passwordError: "",
            confirmPasswordError: "",
          });
        })
        .catch((err) => {
          if (err.response.data.code === 1010) {
            this.setState({
              failHeader: "Invalid Token",
              resetFail:
                "Your password reset token has expired. Please reset your password again to resend it",
              loading: false,
              password: "",
              confirmPassword: "",
              passwordError: "",
              confirmPasswordError: "",
            });
          } else {
            this.setState({
              failHeader: "Error",
              resetFail: "Password hasn't been changed successfully!",
              loading: false,
            });
          }
        });
    }
  };

  // close modal
  close = () => {
    this.setState({ resetFail: "", resetSuccess: false });
  };

  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="Password Reset | Spritzer" />

        <Navbar />

        {this.state.resetSuccess ? (
          <SweetAlert
            success
            title="Successfull"
            onConfirm={this.close}
            confirmBtnText="OK"
            confirmBtnStyle={{ background: "#f26666", border: 0, width: 70 }}
          >
            Password has been reset successfully.
          </SweetAlert>
        ) : null}

        {this.state.resetFail ? (
          <SweetAlert
            danger
            title={this.state.failHeader}
            onConfirm={this.close}
            confirmBtnStyle={{ background: "#f26666", border: 0 }}
          >
            {this.state.resetFail}
          </SweetAlert>
        ) : null}

        <div className="container-fluid p-0" style={{ background: "#FBFBFB" }}>
          <div className="row m-0">
            <div className="col p-0">
              <h2 className="partner-landing-headings pb-0">Reset Password</h2>

              <div className="password-reset-div">
                <form>
                  <div className="form-group">
                    <label htmlFor="password">Create New Password</label>
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": this.state.passwordError,
                      })}
                      id="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.passwordError}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      className={classnames("form-control", {
                        "is-invalid": this.state.confirmPasswordError,
                      })}
                      id="confirmPassword"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                      onKeyUp={this.handleSubmitKeyUp}
                    />
                    <div className="invalid-feedback">
                      {this.state.confirmPasswordError}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="submit-btn mt-2"
                    id="submit"
                    onClick={this.handleSubmit}
                  >
                    {this.state.loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}
                    {this.state.loading ? "Submitting..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
