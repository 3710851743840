import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";
import Navbar from "../components/Navbar";
import Footer from "../components/partnerLandingPageComponents/Footer";
import TitleOfPage from "../components/TitleOfPage";

import "../styles/passwordReset.css";

export default class ActivateEmail extends Component {
  state = {};

  // submit sign-up form
  handleSubmit = () => {
    if (this.validate()) {
      // create user data object
      const data = {
        token: queryString.parse(this.props.location.search).token,
        newPassword: this.state.password,
        clientId: "web",
      };

      // set loading
      this.setState({ loading: true });

      axios
        .post(`${process.env.REACT_APP_API_URL}/user/password/change`, data)
        .then((res) => {
          this.setState({
            resetSuccess: true,
            loading: false,
            password: "",
            confirmPassword: "",
            passwordError: "",
            confirmPasswordError: "",
          });
        })
        .catch((err) => {
          if (err.response.data.code === 1010) {
            this.setState({
              failHeader: "Invalid Token",
              resetFail:
                "Your password reset token has expired. Please reset your password again to resend it",
              loading: false,
              password: "",
              confirmPassword: "",
              passwordError: "",
              confirmPasswordError: "",
            });
          } else {
            this.setState({
              failHeader: "Error",
              resetFail: "Password hasn't been changed successfully!",
              loading: false,
            });
          }
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="Email Activation | Spritzer" />

        <Navbar />
        <div className="container-fluid p-0" style={{ background: "#FBFBFB" }}>
          <div className="row m-0">
            <div className="col p-0">
              <h2 className="partner-landing-headings pb-0">
                Email Activation
              </h2>

              <div className="password-reset-div">
                <p className="text-center">
                  <i className="far fa-check-circle text-success fa-4x"></i>
                </p>
                <p
                  className="text-center mt-4 font-weight-bold"
                  style={{ fontSize: 20 }}
                >
                  Congratulations
                </p>
                <p className="text-center">
                  Your email has been successfully activated!
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
