import React, { Component } from "react";

import feature1 from "../../images/features/quick-setup.png";
import feature2 from "../../images/features/customizable-website.png";
import feature3 from "../../images/features/photo-menu.png";
import feature4 from "../../images/features/mobile-ordering.png";
import feature5 from "../../images/features/accept-online-payments.png";
import feature6 from "../../images/features/sms-email-notification.png";
import feature7 from "../../images/features/order-management-dashboard.png";
import feature8 from "../../images/features/promotional-platforms.png";
import feature9 from "../../images/features/customer-insights.png";
import feature10 from "../../images/features/delivery-pickup-dinein.png";

export default class Features extends Component {
  render() {
    return (
      <div className="container">
        <div className="row m-0 features-sec">
          <div className="col p-0">
            <h2 className="partner-landing-headings pb-0">
              Well-crafted Features
            </h2>
            <p className="how-sub-heading text-center pb-5">
              To manage everything in your restaurant
            </p>
            <div className="row m-0">
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature1} alt="Quick setup" />
                  </div>
                  <div className="col-10 col-md-11 col-lg-10 col-md-11 col-lg-10">
                    <h6>Quick setup</h6>
                    <p className="features-para">
                      Easy onboarding process to set up your online restaurant
                      instantly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature2} alt="Fully-customizable website" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Fully-customizable website</h6>
                    <p className="features-para">
                      Mobile responsive website customized with your brand
                      colors, images and text content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature3} alt="Photo menu" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Photo menu</h6>
                    <p className="features-para">
                      A well-organized photo menu for all your customers,
                      including pre-orders and dine-in.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0 mt-0 mt-lg-4">
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature4} alt="Mobile ordering for diners" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Mobile ordering for diners</h6>
                    <p className="features-para">
                      So you can focus on customer satisfaction while we take
                      care of the ordering process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature5} alt="Accept online payments" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Accept online payments</h6>
                    <p className="features-para">
                      Multiple payment options will ensure customer satisfaction
                      and your payments are guaranteed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature6} alt="SMS/Email notifications" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>SMS/Email notifications</h6>
                    <p className="features-para">
                      Notify your customers at every step of the food
                      preparation and delivery process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0 mt-0 mt-lg-4">
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature7} alt="Order management dashboard" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Order management dashboard</h6>
                    <p className="features-para">
                      Delivery, pickup or dine-in; manage all orders in a single
                      interface. Mobile and Tab supported.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature8} alt="Promotions" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Promotions</h6>
                    <p className="features-para">
                      Offer promo codes and happy hours to attract more
                      customers and cross-sell your food.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature9} alt="Customer Insights" />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Customer Insights</h6>
                    <p className="features-para">
                      Analytics features will guide you to scale your business
                      in a sustainable way.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row m-0 mt-0 mt-lg-4">
              <div className="col-12 col-lg-4 p-0"></div>
              <div className="col-12 col-lg-4 p-0">
                <div className="row m-0">
                  <div className="col-2 col-sm-1 col-lg-2 p-0">
                    <img src={feature10} alt="Delivery, Pickup or Dine-in orders"  />
                  </div>
                  <div className="col-10 col-sm-11 col-lg-10">
                    <h6>Delivery, Pickup or Dine-in orders</h6>
                    <p className="features-para mb-0">
                      Maintain a single interface for all customers and make
                      their life easier.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
