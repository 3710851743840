import React, { Component } from "react";

import client1 from "../../images/restaurant-logos/kaha-bath-gedara.png";
import client2 from "../../images/restaurant-logos/ceylon-city-hotel.png";
import client3 from "../../images/restaurant-logos/surf-eka.png";
import client4 from "../../images/restaurant-logos/health-house.png";
import client5 from "../../images/restaurant-logos/chaat-co.png";
import client6 from "../../images/restaurant-logos/tuk-tuk-wine.png";
import client7 from "../../images/restaurant-logos/tea-talk.png";
import client8 from "../../images/restaurant-logos/waffle-queen.png";
import client9 from "../../images/restaurant-logos/hunger-street.png";
import client10 from "../../images/restaurant-logos/the-sizzle.png";

export default class OurClients extends Component {
  render() {
    return (
      <div className="container">
        <div className="row m-0 our-clients-sec">
          <div className="col p-0">
            <div className="border-top clients-heading-div">
              <p
                className="pb-0 px-3 our-clients-heading"
              >
                These legends have already gone online with Spritzer
              </p>
            </div>

            <div className="row m-0">
              <div className="col p-0">
                <img src={client1} alt="kaha-bath-gedara" />
                <img src={client2} alt="ceylon-city-hotel" />
                <img src={client3} alt="surf-eka" />
                <img src={client4} alt="health-house" />
                <img src={client5} alt="chaat-co" />
                <img src={client6} alt="artboard" />
                <img src={client7} alt="tea-talk.jpg" />
                <img src={client8} alt="waffle-queen" />
                <img src={client9} alt="hunger-street" />
                <img src={client10} alt="the-sizzle" />
                <a href="https://menu.spritzer.app/" target="_blank" rel="noopener noreferrer" className="see-customers">
                  SEE ALL RESTAURANTS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
