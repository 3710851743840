import React, { Component } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/partnerLandingPageComponents/Footer";
import TitleOfPage from "../components/TitleOfPage";

export default class TermsAndCondition extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="Terms | Spritzer" />

        <div className="container-fluid p-0" style={{ background: "#FBFBFB" }}>
          <Navbar />
          <div className="container">
            <div className="row m-0">
              <div className="col p-0">
                <h2 className="partner-landing-headings pb-0">
                  Terms of Services
                </h2>
                <p className="how-sub-heading text-center pb-4">
                  Last updated date: 08 May 2020
                </p>
              </div>
            </div>
            <div className="row m-0 terms-section">
              <div className="col p-0">
                <p>
                  Welcome to Spritzer (also referred to as "Spritzer”, "Spritzer
                  App", "we," "us" or "our"). Spritzer is a fully-fledged
                  technology solution for restaurants, pubs, bars and cafes
                  (named hereafter as “Restaurant”) to seamlessly manage several
                  online and in-store operations.
                </p>

                <p>
                  Spritzer is owned and operated by Spritzer Labs Pty Ltd (ACN
                  628408604) which is fully owned by Serendib Systems Pty Ltd
                  (ACN 637232038), and its affiliate (distribution) partners. By
                  accessing and/or using Spritzer or related services, you agree
                  to these Terms and Conditions, which include our Privacy
                  Policy.
                </p>

                <ol>
                  <li>
                    <h2>Definitions</h2>
                    <p>
                      Definitions of the terms and abbreviations used in this
                      document
                    </p>
                    <ol class="alpha">
                      <li>
                        Company means Spritzer Labs Pty Ltd. – ACN 628408604
                      </li>
                      <li>
                        Website means either www.spritzer.app and its subpages.
                      </li>
                      <li>
                        User means whoever uses our technology to build their
                        online ecommerce restaurants.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Registration</h2>
                    <p>
                      Only one person can be the “Restaurant Owner”, usually the
                      person signing up for the Spritzer service. The Restaurant
                      Owner is responsible for the account, is bound by these
                      Terms of Service and is responsible for the actions of
                      others accessing the account. If you sign up on behalf of
                      your employer, your employer is the Restaurant Owner
                      responsible for your account.
                    </p>
                    <ol class="alpha">
                      <li>
                        When you register and activate your account, you will
                        provide us with personal information such as your name
                        and email address. You must ensure that this information
                        is accurate and current. We will handle all personal
                        information we collect in accordance with our Privacy
                        Policy.
                      </li>
                      <li>
                        To create an account with Spritzer, you must be 18 years
                        old at the time of registration.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Collection of Data</h2>
                    <ol class="alpha">
                      <li>
                        We collect personal information about you when you
                        register on our platform, sign up for our newsletter,
                        enter information on our site or when you use the
                        service via our app in order to,
                        <ol class="roman">
                          <li>
                            Provide information and updates about our services.
                          </li>
                          <li>
                            Be more responsive to your customer service
                            requests.
                          </li>
                          <li>Update you on new services and opportunities.</li>
                          <li>
                            Improve our products and services to better serve
                            you, understanding your needs.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Our Privacy Policy explains,
                        <ol class="roman">
                          <li>
                            How we store and use, and how you may access and
                            correct your personal information.
                          </li>
                          <li>
                            How you can lodge a complaint regarding the handling
                            of your personal information.
                          </li>
                          <li>How we will securely handle any data.</li>
                          <p>
                            If you would like further information about our
                            privacy policies or practices, please contact us at{" "}
                            <a href="mailto:support@spritzer.app">
                              support@spritzer.app
                            </a>
                          </p>
                        </ol>
                      </li>
                      <li>
                        By providing your personal information to us, you
                        consent to the collection, use, storage and disclosure
                        of that information as described in the Privacy Policy
                        and these Terms.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Service</h2>
                    <ol class="alpha">
                      <li>
                        The Restaurant Owner is solely responsible for the
                        compliance and adherence to all laws, regulations and
                        expected operating standards related to their business.
                      </li>
                      <li>
                        Services provided by Spritzer for its Users are subject
                        to a fee, which depends on the operational city, venue
                        and the specific service being offered. Pricing details
                        are communicated well in advance at the sign-up process
                        for each service.
                      </li>
                      <li>
                        Spritzer is not a food or beverages vendor and does not
                        accept any liability arising from the sale or
                        consumption of food and beverages at the Restaurant.
                      </li>

                      <li>
                        Customers of the Restaurants are aware that services
                        provided at the Restaurants are completely out of
                        Spritzer’s control and we will take reasonable endeavors
                        to correct any dissatisfaction arises because of the
                        technology we provide.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Warranty Disclaimers</h2>
                    <ol class="alpha">
                      <li>
                        We do not represent or warrant that:
                        <ol class="roman">
                          <li>
                            Any content or information accessible via the
                            websites or applications is accurate, complete,
                            reliable, current or error-free including the prices
                            and availability. Such information is subject to
                            change without notice.
                          </li>
                          <li>
                            Your use of the Service will be uninterrupted and we
                            do not warrant that any information transmitted via
                            the Service or the platform will be transmitted
                            accurately, reliably, in a timely manner or at all.
                          </li>
                        </ol>
                      </li>
                      <li>
                        We will use reasonable endeavours to correct any errors
                        or omissions as soon as practicable after being notified
                        of the same.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Suspension and Termination</h2>
                    <ol class="alpha">
                      <li>
                        If you use the Sites or Service in contravention of this
                        Agreement, we may suspend your use of the Service and/or
                        Sites (in whole or in part).
                      </li>
                      <li>
                        We shall fully cooperate with any law enforcement
                        authorities or court order requesting or directing us to
                        disclose the identity or locate anyone in breach of this
                        Agreement.
                      </li>
                      <li>
                        Our rights to terminate this Agreement shall not
                        prejudice any other right or remedy we may have in
                        respect of any breach or any rights, obligations or
                        liabilities accrued prior to termination.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Promotions</h2>
                    <ol class="alpha">
                      <li>
                        For certain campaigns, promotions or contests,
                        additional terms and conditions may apply. If you
                        participate in such campaigns, promotions or contests,
                        you need to agree to the relevant terms and conditions
                        applicable to that campaign, promotion or contest. In
                        case of any inconsistency between such terms and
                        conditions and these Terms, those terms and conditions
                        will prevail.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Copyright Infringement</h2>
                    <ol class="alpha">
                      <li>
                        We reserve the right to refuse to post, remove in their
                        entirety, or edit any Submissions on the Sites for any
                        reason, in its absolute and sole discretion. If you
                        believe in good faith that Submissions posted by the
                        Sites infringe your copyright, you (or your agent) may
                        send us a notice requesting that the Submission(s) be
                        removed from the platform.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Sale and Assignment</h2>
                    <ol class="alpha">
                      <li>
                        If we are involved in a merger, acquisition, asset sale
                        or change in control, we may assign, novate or otherwise
                        transfer its rights and obligations that arise under
                        these terms without reference to you so that in the
                        future, the Services are provided by a different party.
                      </li>
                      <li>
                        IIn such circumstances, we reserve the right to transfer
                        or assign your personal information as provided in our
                        Privacy Policy.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>
                      By using Spritzer, you warrant and represent that you:
                    </h2>
                    <ol class="alpha">
                      <li>are capable of forming legally binding contracts;</li>
                      <li>
                        will not provide your Spritzer log-on credentials to any
                        third party;
                      </li>
                      <li>
                        will not use Spritzer platform in a way that breaches
                        these terms of use, any laws or infringes any rights of
                        third parties;
                      </li>
                      <li>
                        will not copy, scrape or reproduce content from Spritzer
                        including information about third parties without their
                        consent.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Feedback and/or complaints</h2>
                    <p>
                      We welcome any feedback our customers have regarding the
                      Spritzer platform and how we can improve your experience
                      when using our services. In the event of any bug or
                      software issues and/or complaints, please contact us
                      directly via{" "}
                      <a href="mailto:support@spritzer.app">
                        support@spritzer.app
                      </a>
                    </p>
                  </li>

                  <li>
                    <h2>Updates to Terms and Conditions</h2>
                    <p>
                      It may be necessary for Spritzer to modify or update these
                      terms and conditions from time to time, for example, to
                      reflect updates to the Service or where required by law.
                      We will notify you before we make changes and give you the
                      opportunity to review the revised statement before
                      continuing to use the Service. Your continued use of our
                      Service or the Service after any modification to this
                      statement will constitute your acceptance of such
                      modification.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
