import React, { Component } from "react";
import axios from "axios";

import Orders from "../components/pastOrders/Orders";
import Navbar from "../components/partnerLandingPageComponents/Navbar";
import TitleOfPage from "../components/TitleOfPage";

import "../styles/pastOrders.css";

import { isAuthenticated } from "../auth/auth";

export default class PastOrders extends Component {
  state = {
    orders: []
  };

  componentDidMount = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/delivery_orders`, {
        headers: {
          Authorization: `Bearer ${
            isAuthenticated().providerToken
          }`,
        },
      })
      .then((res) => {
        console.log(res.data);

        this.setState({
         orders: res.data
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <TitleOfPage title="Past Orders | Spritzer" />

        <div className="container" style={{minHeight: 825}}>
          <div className="row m-0">
            <div className="col p-0">
              <h2 className="mt-4">Past Orders</h2>
              {this.state.orders.map((order, i) => (
                <Orders orders={order} key={i} listingId={order.listingId} />
              ))}
            </div>
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}
