import React, { Component } from "react";
import axios from "axios";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Navbar from "../components/Navbar";
import Footer from "../components/partnerLandingPageComponents/Footer";
import TitleOfPage from "../components/TitleOfPage";

import google from "../images/icon-google-play--black.png";

import "../styles/faqStyles.css";

export default class Contact extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    nameError: "",
    emailError: "",
    phoneError: "",
    subjectError: "",
    messageError: "",

    // loading
    loading: false,

    // messages
    successAlert: false,
    errorAlert: false,
  };

  // get input value
  handleChangeInputs = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // validate inputs
  validate = () => {
    let nameError = "";
    let emailError = "";
    let phoneError = "";
    let subjectError = "";
    let messageError = "";

    if (this.state.name === "") {
      nameError = "Name is required";
    }

    if (this.state.email === "") {
      emailError = "Email is required";
    } else if (!this.state.email.includes("@")) {
      emailError = "Email is invalid";
    }

    if (this.state.phone === "") {
      phoneError = "Contact No is required";
    }

    if (this.state.subject === "") {
      subjectError = "Subject is required";
    }

    if (this.state.message === "") {
      messageError = "Message is required";
    }

    if (nameError || emailError || phoneError || subjectError || messageError) {
      this.setState({
        nameError,
        emailError,
        phoneError,
        subjectError,
        messageError,
      });
      return false;
    }

    return true;
  };

  // submit
  submitContact = () => {
    if (this.validate()) {
      const contactDetails = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        text: this.state.message,
      };

      // set loading
      this.setState({ loading: true });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v2/contact`, contactDetails)
        .then((res) => {
          this.setState({
            successAlert: true,
            loading: false,
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            nameError: "",
            emailError: "",
            phoneError: "",
            subjectError: "",
            messageError: "",
          });
        })
        .catch((err) => {
          this.setState({
            errorAlert: true,
            loading: false,
            nameError: "",
            emailError: "",
            phoneError: "",
            subjectError: "",
            messageError: "",
          });
        });
    }
  };

  // close modal
  close = () => {
    this.setState({ successAlert: false, errorAlert: false });
  };

  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="Contact | Spritzer" />
        <div className="container-fluid p-0" style={{ background: "#FBFBFB" }}>
          <Navbar />

          {this.state.successAlert ? (
            <SweetAlert
              success
              title="Successfull"
              onConfirm={this.close}
              confirmBtnText="Ok"
              confirmBtnStyle={{ background: "#f26666", border: 0, width: 70 }}
            >
              Thank You! One of our team members will get back to you soon.
            </SweetAlert>
          ) : null}

          {this.state.errorAlert ? (
            <SweetAlert
              danger
              title="Oops, Something went wrong"
              onConfirm={this.close}
              confirmBtnStyle={{ background: "#f26666", border: 0 }}
            >
              Your message wasn't sent!
            </SweetAlert>
          ) : null}

          <div className="container">
            <div className="row m-0">
              <div className="col p-0">
                <h2 className="partner-landing-headings">Contact Us</h2>
                <div className="contact-form-div my-4">
                  <form className="mx-0">
                    <div className="form-row m-0">
                      <div className="form-group mb-3 p-0 business-name-col w-100">
                        <input
                          type="text"
                          className={classnames("form-control", {
                            "is-invalid": this.state.nameError,
                          })}
                          name="name"
                          placeholder="Name *"
                          value={this.state.name}
                          onChange={this.handleChangeInputs}
                        />
                        <div className="invalid-feedback">
                          {this.state.nameError}
                        </div>
                      </div>
                    </div>
                    <div className="form-row m-0">
                      <div className="form-group mb-3 p-0 business-name-col w-100">
                        <input
                          type="text"
                          className={classnames("form-control", {
                            "is-invalid": this.state.emailError,
                          })}
                          name="email"
                          placeholder="Email *"
                          value={this.state.email}
                          onChange={this.handleChangeInputs}
                        />
                        <div className="invalid-feedback">
                          {this.state.emailError}
                        </div>
                      </div>
                    </div>
                    <div className="form-row m-0">
                      <div className="form-group mb-3 p-0 business-name-col w-100">
                        <PhoneInput
                          country={"lk"}
                          value={this.state.phone}
                          // disableDropdown={true}
                          // countryCodeEditable={false}
                          enableSearch={true}
                          disableSearchIcon={true}
                          onChange={(phone) => this.setState({ phone })}
                          prefix=""
                        />
                        <div
                          className="text-danger"
                          style={{ fontSize: "90%", marginTop: 5 }}
                        >
                          {this.state.phoneError}
                        </div>
                      </div>
                    </div>

                    <div className="form-row m-0">
                      <div className="form-group mb-3 p-0 business-name-col w-100">
                        <input
                          type="text"
                          className={classnames("form-control", {
                            "is-invalid": this.state.subjectError,
                          })}
                          name="subject"
                          placeholder="Subject *"
                          value={this.state.subject}
                          onChange={this.handleChangeInputs}
                        />
                        <div className="invalid-feedback">
                          {this.state.subjectError}
                        </div>
                      </div>
                    </div>
                    <div className="form-row m-0">
                      <div className="form-group mb-3 p-0 w-100">
                        <textarea
                          className={classnames("form-control w-100", {
                            "is-invalid": this.state.messageError,
                          })}
                          name="message"
                          placeholder="Message *"
                          value={this.state.message}
                          onChange={this.handleChangeInputs}
                          style={{ height: "120px" }}
                        />
                        <div className="invalid-feedback">
                          {this.state.messageError}
                        </div>
                      </div>
                    </div>
                  </form>
                  <button className="send-btn" onClick={this.submitContact}>
                    {this.state.loading ? (
                      <div
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : null}

                    {this.state.loading ? "Sending..." : "Send"}
                  </button>
                </div>

                <h4 className="text-center mt-5" style={{ fontWeight: 400 }}>
                  Download the Order Manager App on Android.
                </h4>

                <div className="store-div">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.spritzer.restaurantmanager"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={google}
                      className="store-icons"
                      alt="Download user app from google play store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
