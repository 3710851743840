import React, { useState } from "react";
import axios from "axios";
import classnames from "classnames";

export default function ForgetPasswordModal() {
  const [emailRequest, setEmailRequest] = useState("");
  const [emailRequestError, setEmailRequestError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  // chnage input
  const handleChange = e => {
    setEmailRequest(e.target.value);
  };

  // validate
  const validate = () => {
    let emailRequestError = "";

    if (emailRequest === "") {
      emailRequestError = "Email is required";
    } else if (!emailRequest.includes("@")) {
      emailRequestError = "Email is invalid";
    }

    if (emailRequestError) {
      setEmailRequestError(emailRequestError);
      return false;
    }

    return true;
  };

  // forget password
  const ForgetPassowrdReset = () => {
    if (validate()) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/user/password/reset?email=${emailRequest}`
        )
        .then(res => {
          setEmailRequest("");
          setSuccessMsg(res.data.message);
        })
        .catch(err => {
          if (err.response.data.code === 1012) {
            setEmailRequest("");
            setErrorMsg("Email is not found");
          }
        });
    }
  };

  return (
    <div
      className="modal fade update-bank-account-modal"
      id="forgetpasswordModel"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="forgetpassword"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="update-bank-model-heading mb-0">
              Send reset password email
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pt-2">
            <p className="text-success mb-0 ml-1">{successMsg}</p>
            <p className="text-danger mb-0 ml-1">{errorMsg}</p>
            <form>
              <div className="form-row m-0">
                <div className="col mb-2">
                  <label
                    htmlFor="emailRequest"
                    className="normal-bold-text ml-0"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control forget-email-input", {
                      "is-invalid": emailRequestError
                    })}
                    id="emailRequest"
                    value={emailRequest}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">{emailRequestError}</div>
                </div>
              </div>
            </form>
            <button
              className="save-btn w-100 mx-auto mt-3 mb-0"
              onClick={ForgetPassowrdReset}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
