import React, { Component } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/partnerLandingPageComponents/Footer";
import TitleOfPage from "../components/TitleOfPage";

export default class TermsAndCondition extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleOfPage title="Privacy Policy | Spritzer" />

        <div className="container-fluid p-0" style={{ background: "#FBFBFB" }}>
          <Navbar />
          <div className="container">
            <div className="row m-0">
              <div className="col p-0">
                <h2 className="partner-landing-headings pb-0">
                  Privacy Policy
                </h2>
                <p className="how-sub-heading text-center pb-4">
                  Last updated date: 08 May 2020
                </p>
              </div>
            </div>
            <div className="row m-0 terms-section">
              <div className="col p-0">
                <p>
                  Welcome to Spritzer (also referred to as "Spritzer”, "Spritzer
                  App", "we," "us" or "our"). Spritzer is a fully-fledged
                  technology solution for restaurants to seamlessly manage
                  several online and in-store operations.
                </p>
                <ul>
                  <li>
                    Our Privacy Policy explains how we use, share and protect
                    information in relation to our web site, apps or and any
                    software provided in connection with Spritzer services (the
                    “Service”), and your choices about the collection and use of
                    your information.
                  </li>
                  <li>
                    Our Policy applies to all visitors, users, and others who
                    access the Service ("Users").
                  </li>
                </ul>
                <ol>
                  <li>
                    <h2>We respect your privacy</h2>
                    <ol class="alpha">
                      <li>
                        When you use Spritzer, you trust us with your
                        information. We are committed to keeping that trust.
                        That starts with helping you understand our privacy
                        practices.
                      </li>
                      <li>
                        We adhere to the National Privacy Principles established
                        by the Privacy Act 1988. This policy sets out how we
                        collect and treat your personal information.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Collection of personal information</h2>
                    <ol class="alpha">
                      <li>
                        We collect information from you when you register on our
                        app, sign up for our newsletter, enter information on
                        our site or when you use the service via our app.
                      </li>
                      <li>
                        You may provide basic information such as your name,
                        phone number and email address to enable us to send
                        information, provide updates and process your product or
                        service order. We may collect additional information
                        created when you use our services and this may include
                        Location information, Transaction information, Usage
                        information, Device information and Communication data.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>How we use your information</h2>
                    <ol class="alpha">
                      <li>
                        We may use the information we collect from you to serve
                        you better in various ways including;
                        <ol class="roman">
                          <li>
                            Provide information and updates about our services
                          </li>
                          <li>
                            Better response to your customer service requests
                          </li>
                          <li>Update you on new services and opportunities</li>
                          <li>
                            Improve our products and services to better serve
                            you, understanding your needs
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Cookies and Third Party Technologies</h2>
                    <ol class="alpha">
                      <li>
                        Cookies are small text files that are stored on your
                        browser or device by websites, apps, online media and
                        advertisements. Spritzer uses cookies and similar
                        technologies for purposes such as:
                        <ol class="roman">
                          <li>Authenticating users</li>
                          <li>Remembering user preferences and settings</li>
                          <li>Determining the popularity of content</li>
                          <li>
                            Delivering and measuring the effectiveness of
                            advertising campaigns
                          </li>
                          <li>Analysing site traffic and trends</li>
                        </ol>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Information sharing and disclosure</h2>
                    <ol class="alpha">
                      <li>
                        We do not sell, trade, or otherwise transfer your
                        personal information to outside parties. This does not
                        include website hosting partners and other parties who
                        assist us in operating our website, conducting our
                        business, or serving our users. We share information
                        with those parties with agreement to keep user
                        information confidential. We may also release
                        information when it’s required to comply with the law,
                        enforce our site policies or to protect ours or others’
                        rights, property or safety.
                      </li>
                      <li>
                        Non-personally identifiable visitor information may be
                        provided to other parties for marketing, advertising or
                        other uses.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Security of your personal information</h2>
                    <ol class="alpha">
                      <li>
                        Your personal information is protected behind secured
                        networks and is only accessible by authorized persons
                        who have special access rights to such systems, and are
                        required to keep the information confidential.
                      </li>
                      <li>
                        All transactions are processed through a gateway
                        provider and are not stored or processed on our servers.
                      </li>
                      <li>
                        Required security measures are implemented when a user
                        submits or accesses their information to safeguard
                        personal information of Spritzer users.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>Third party services</h2>
                    <ol class="alpha">
                      <li>
                        We may include or offer third-party products or services
                        on our website. These third-party sites have separate
                        and independent privacy policies. We therefore have no
                        responsibility or liability for the content and
                        activities of these linked sites.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>California Online Privacy Protection Act (CalOPPA)</h2>
                    <ol class="alpha">
                      <li>
                        In accordance with the CalOPPA, we agree that:
                        <ol class="roman">
                          <li>Users can visit our site anonymously</li>
                          <li>
                            Once this privacy policy is created, we will add a
                            link to it on our home page or as a minimum, on the
                            first significant page after entering our website.
                          </li>
                          <li>
                            Our Privacy Policy link includes the word ‘Privacy’
                            and can be easily found on the page specified above.
                          </li>
                          <li>
                            Users will be notified of any Privacy Policy changes
                            on our Privacy Policy Page, and
                          </li>
                          <li>
                            Users can change their personal information by
                            logging into their account.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h2>Children’s Privacy</h2>
                    <p>
                      Children Online Privacy Protection Act of 1998 (COPPA)
                      covers the collection of personal information from
                      children under the age of 13 years old. The Federal Trade
                      Commission, United States’ consumer protection agency,
                      enforces the COPPA Rule, which spells out what operators
                      of websites and online services must do to protect
                      children’s privacy and safety online. Please refer to
                      (link) for further information.
                    </p>
                    <ol class="alpha">
                      <li>
                        We do not specifically market to children under the age
                        of 13 years old.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h2>How to contact us</h2>
                    <p>
                      Iou have any questions about this Privacy Policy or the
                      Service, please contact us directly via{" "}
                      <a href="mailto:support@spritzer.app">
                        support@spritzer.app
                      </a>
                    </p>
                  </li>
                  <li>
                    <h2>Updates to this Policy</h2>
                    <p>
                      It may be necessary for Spritzer to modify or update this
                      Privacy Policy from time to time, for example to reflect
                      updates to the Service or where required by law. We will
                      notify you before we make changes to this Privacy Policy
                      and give you the opportunity to review the revised Privacy
                      Policy before continuing to use the Service. Your
                      continued use of our service or the Service after any
                      modification to this Privacy Policy will constitute your
                      acceptance of such modification.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
