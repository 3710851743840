import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/spritzer-logo.png";

export default function Navbar() {
  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-white bg-white py-3 px-0 business-app-navbar">
      <div className="container">
        <Link className="navbar-brand p-0" to="/">
          <img
            src={logo}
            className="company-logo"
            alt="Spritzer Business Logo"
          />
        </Link>
        <div className="ml-auto">
          <a
            href={
              process.env.REACT_APP_API_URL === "https://api-dev.spritzer.app"
                ? "https://dashboard-dev.spritzer.app/manage-orders"
                : "https://dashboard.spritzer.app/manage-orders"
            }
            rel="noopener noreferrer"
            target="_blank"
            className="nav-item d-none d-sm-inline-block"
            style={{ fontWeight: 400 }}
          >
            Login
          </a>
          <a
            href={
              process.env.REACT_APP_API_URL === "https://api-dev.spritzer.app"
                ? "https://dashboard-dev.spritzer.app/sign-up"
                : "https://dashboard.spritzer.app/sign-up"
            }
            rel="noopener noreferrer"
            target="_blank"
            className="nav-item sign-up-btn"
          >
            Build My Restaurant
          </a>
        </div>
      </div>
    </nav>
  );
}
