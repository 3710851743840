import React, { Component } from "react";
import { Link } from "react-router-dom";
import TextTransition, { presets } from "react-text-transition";
import mainImage from "../../images/partner-landing-page/main-image.png";

export default class Jumbotron extends Component {
  state = {
    backgroundImage: "bg",
    texts: ["Restaurant", "Café", "Bar", "Pub"],
    index: 0,
  };

  handleBgImage = () => {
    if (window.innerWidth < 767) {
      this.setState({ backgroundImage: "bgMobile" });
    } else {
      this.setState({ backgroundImage: "bg" });
    }
  };

  componentDidMount = () => {
    this.handleBgImage();
    window.addEventListener("resize", this.handleBgImage);

    setInterval(
      () => this.setState({ index: this.state.index + 1 }),
      3000 // every 3 seconds
    );
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleBgImage);
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid bg-white p-0">
          <div className="container">
            <div className="row m-0 pb-5">
              <div className="col-md col-lg-5 col-xl-4 p-0">
                <div className="jumbotron-tex-dev-non-mobile">
                  <h1 className="jumbotron-heading">
                    Build Your <span style={{ color: "#ff6961" }}>Online</span>{" "}
                    <TextTransition
                      text={
                        this.state.texts[
                          this.state.index % this.state.texts.length
                        ]
                      }
                      springConfig={presets.slow}
                      direction="down"
                    />
                  </h1>
                  <p className="jumbotron-subheading">
                    Customizable, scalable & hosted software to manage and
                    increase your sales.
                  </p>
                  <a
                    href={
                      process.env.REACT_APP_API_URL ===
                      "https://api-dev.spritzer.app"
                        ? "https://dashboard-dev.spritzer.app/sign-up"
                        : "https://dashboard.spritzer.app/sign-up"
                    }
                    target="_blank"
                    className="jumbotron-go-live-btn"
                    rel="noopener noreferrer"
                  >
                    Go Live Today
                  </a>
                </div>
              </div>
              <div
                className="col-md col-lg-7 col-xl-8 p-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={mainImage}
                  className="main-image-layer w-100"
                  alt="main background"
                  style={{ position: "relative" }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
